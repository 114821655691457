import { AuthorizedContent } from '@frontegg/react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';
import getApiChatbotResponse from '../../../api/getApiChatbotResponse';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme';
import { EditIconStyled } from '../../../theme/style';
import AlertType from '../../../utils/enum/AlertType';
import RolesType from '../../../utils/enum/RolesType';
import useFetcher from '../../../utils/hooks/useFetcher';
import Loader from '../../Loader/loader';
import Message from '../../Message/Message';
import DeleteIndicatorModal from '../../Modals/ConfirmationModal/DeleteIndicator';
import { getLastPageForEditKpi, CHART_INDICATOR_NAME } from '../../Modals/DashboardModal/constants';
import DashboardModal from '../../Modals/DashboardModal/dashboardModal';
import { ChatingProps } from '../../Modals/DashboardModal/dashboardModalInterface';
import EditIndicatorModal from '../../Modals/EditModal/EditIndicator';
import ActionsCard from '../ActionsCard/actionsCard';
import { ChatProps, ClauseFilterProps, DashboardParams, DateRangeScopeProps, Indicator, ScopeProps } from '../dashboardInterfaces';
import { IndicatorCardStyled, IndicatorCardHeader, IndicatorCardHeaderBase, ButtonCardStyled, ButtonDivStyled } from '../styled';

interface IndicatorCardProps {
    indicator: Indicator
    selectIndicatorCard: (additionalVars: Indicator['additionalVars'], displayType: Indicator['displayType'], data: Indicator['data'], question: Indicator['question'], getChatbotResponse: (isSummary: boolean, question: string) => void, conversation: Array<ChatingProps>, summary: string, isSummaryDisplayed: boolean, waitingForChatAnswer: boolean, handleIsRenderedChat: (chatId: number) => void) => any,
    selectedClauseFilter: Array<ClauseFilterProps>
    selectedScope: Array<ScopeProps>
    selectedDateRange: DateRangeScopeProps | null
    isDragging? : boolean
}

const IndicatorCard: FC<IndicatorCardProps> = ({ indicator, selectIndicatorCard, selectedClauseFilter = [], selectedScope = [], selectedDateRange, isDragging = false }) => {
    const { t } = useTranslation('dashboard');
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const [conversation, setConversation] = useState<Array<ChatingProps>>([]);
    const [isChatStarted, setIsChatStarted] = useState<boolean>(true);
    const [chatId, setChatId] = useState<string>(uuidv4());
    const [summary, setSummary] = useState<string>('');
    const clauseFilters = JSON.stringify(selectedClauseFilter.map((clauseFilter: ClauseFilterProps) => ({ [clauseFilter.filterId]: clauseFilter.modalityId.map((modalityId: string) => parseInt(modalityId, 10)) })));
    const selectedScopes = JSON.stringify(selectedScope.map((scope: ScopeProps) => ({ [scope.scopeId]: scope.modalityId.map((modalityId: string) => parseInt(modalityId, 10)) })));
    const selectedDateRanges = JSON.stringify({ startDate: selectedDateRange?.startDate.toLocaleDateString('fr-CA'), endDate: selectedDateRange?.endDate.toLocaleDateString('fr-CA'), key: selectedDateRange?.key });
    const filterScopeArray = [selectedClauseFilter.length > 0 ? `filters=${clauseFilters}` : '', selectedScope.length > 0 ? `scopes=${selectedScopes}` : '', selectedDateRange ? `dateRanges=${selectedDateRanges}` : ''].filter((fs: string) => fs !== '');
    const filterScopeLink = filterScopeArray.length > 0 ? `/?${filterScopeArray.join('&')}` : '';
    const { data } = useSWR(indicator.displayType !== CHART_INDICATOR_NAME.CHATBOT ? `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/compute/${indicator.id}/${indicator.displayType}${filterScopeLink}` : '', useFetcher()) ?? {};
    const [isSummaryDisplayed, setIsSummaryDisplayed] = useState<boolean>(false);
    const [waitingForChatAnswer, setWaitingForChatAnswer] = useState<boolean>(false);

    useEffect(() => {
        setSummary('');
        setChatId(uuidv4());
        setIsChatStarted(true);
        setConversation([]);
        setIsSummaryDisplayed(false);
        setWaitingForChatAnswer(false);
    }, [selectedClauseFilter, selectedScope, selectedDateRange]);

    const getChatbotResponse = async (isSummary: boolean, question: string) => {
        const idConversation = conversation.length + 1;
        if (!isSummary) {
            setConversation((prev) => [...prev, { id: idConversation, entry: question, answer: '...' }]);
        } else {
            setSummary('');
            setIsSummaryDisplayed(true);
        }
        setWaitingForChatAnswer(true);
        const chat: ChatProps = { starter: isChatStarted, isSummary, question };
        const chatResponse = await getApiChatbotResponse(surveyId, dashboardId, indicator.id, filterScopeLink, chatId, JSON.stringify(chat));
        if (!isSummary) {
            setConversation((prev) => [...prev.slice(0, -1), { id: idConversation, entry: chat.question, answer: chatResponse.response }]);
        } else {
            setSummary(chatResponse.response);
        }
        setWaitingForChatAnswer(false);
        setIsChatStarted(false);
    };

    const handleIsRenderedChat = (chatIdRendered: number) => {
        const indexOfRederedChat = conversation.findIndex((c: ChatingProps) => (c.id === chatIdRendered));
        conversation[indexOfRederedChat].alreadyRendered = true;
    };

    const content = selectIndicatorCard(indicator.additionalVars, indicator.displayType, data, indicator.question, getChatbotResponse, conversation, summary, isSummaryDisplayed, waitingForChatAnswer, handleIsRenderedChat);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const handleDeleteCard = () => {
        setOpenDeleteModal(true);
    };
    const handleEditTitleCard = () => {
        setOpenEditModal(true);
    };

    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const { data: editIndicator } = useSWR(isEdit ? `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/indicator/${indicator.id}` : null, useFetcher());
    const { data: editQuestion } = useSWR(editIndicator ? `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/questions/${editIndicator.questionId}` : null, useFetcher());

    return (
        <IndicatorCardStyled>
            <IndicatorCardHeader>
                <TypographyStyledGlobal size={fontSize.um} weight={fontWeight.semiBold}>
                    {indicator.customItemTitle || indicator.question.label}
                    { !isDragging && (
                        <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                            <ButtonCardStyled onClick={handleEditTitleCard} variant='text'>({t('edit')})</ButtonCardStyled>
                        </AuthorizedContent>
                    )}
                </TypographyStyledGlobal>
                { !isDragging && (
                    <ButtonDivStyled>
                        <EditIconStyled
                            onClick={() => {
                                setOpenModal(true);
                                setIsEdit(true);
                            }}
                            sx={{ width: '0.8em', height: '0.92em' }}
                        />
                        <ActionsCard handleDeleteCard={handleDeleteCard} />
                    </ButtonDivStyled>
                )}
            </IndicatorCardHeader>
            {!isDragging && indicator.displayType !== CHART_INDICATOR_NAME.CHATBOT && (
                <IndicatorCardHeaderBase>
                    <TypographyStyledGlobal color={colors.grey400} fontStyle='italic' size={fontSize.sm} weight={fontWeight.semiRegular}>
                        { t('base', { count: data?.base }) }
                    </TypographyStyledGlobal>
                </IndicatorCardHeaderBase>
            )}
            {!isDragging && data?.error && <Message content={data.error} messageType={AlertType.WARNING} />}
            {(!isDragging && content) ?? <Loader size={50} />}
            <DeleteIndicatorModal indicator={indicator} openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} />
            <EditIndicatorModal indicator={indicator} openModal={openEditModal} setOpenModal={setOpenEditModal} />
            <DashboardModal defaultView={getLastPageForEditKpi(indicator.displayType)} editIndicator={editIndicator} editQuestion={editQuestion} isEdit={isEdit} openModal={openModal} rankingParameter={{ columnToApply: 0, rankToApply: 0 }} setOpenModal={setOpenModal} />
        </IndicatorCardStyled>
    );
};

export default IndicatorCard;
