import { Box, Tab, Tabs } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import createIndicatorFetcher from '../../../api/dashboard/createIndicator';
import recreateIndicatorFetcher from '../../../api/dashboard/recreateIndicator';
import { DashboardParams } from '../../Dashboard/dashboardInterfaces';
import { CHART_INDICATOR_NAME, CreateIndicatorContext, QUESTION_TYPE } from './constants';
import AddGlobalVerbatimIndicator from './DashboardModalContent/DashboardModalStep/addGlobalVerbatimIndicator';
import AddNumericIndicator from './DashboardModalContent/DashboardModalStep/addNumericIndicator';
import AddVerbatimIndicator from './DashboardModalContent/DashboardModalStep/addVerbatimIndicator';
import RefineIndicatorAnswer from './DashboardModalContent/DashboardModalStep/refineIndicatorAnswer';
import SelectIndicatorDisplayType from './DashboardModalContent/DashboardModalStep/selectIndicatorDisplayType';
import SelectIndicatorQuestion from './DashboardModalContent/DashboardModalStep/selectIndicatorQuestion';
import SelectIndicatorType from './DashboardModalContent/DashboardModalStep/selectIndicatorType';
import TabPanel from './DashboardModalContent/TabPanel/tabPanel';
import { CreateIndicatorFetchProps, SelectedQuestionProps } from './dashboardModalInterface';

interface CreateIndicatorProps {
    defaultView?: string
    editIndicator?: any
    editQuestion?: any
    setOpenModal: Dispatch<SetStateAction<boolean>>
    isEdit?: boolean
    rankingParameter: { columnToApply: number, rankToApply: number };
}

const CreateIndicator: FC<CreateIndicatorProps> = ({ defaultView, editIndicator, editQuestion, setOpenModal, isEdit, rankingParameter }) => {
    const { t } = useTranslation('dashboard');
    const { surveyId = 'unknown', dashboardId = 'unknown' } = useParams<DashboardParams>();
    const dashboardUrl = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}`;
    const { mutate } = useSWRConfig();

    const [currentContent, setCurrentContent] = useState(defaultView || 'selectIndicatorType');
    // TODO : https://thetribeio.atlassian.net/browse/MOAI-104

    const [createIndicator, setCreateIndicator] = useState<CreateIndicatorFetchProps>(editIndicator || {
        additionalVars: [],
        questionId: -999999,
        typeOfIndicator: 'KPI',
        imgUrl: '',
        kpiType: 'NUMERIC',
        displayType: '',
        positiveVerbatimToShow: [],
        negativeVerbatimToShow: [],
        customItemTitle: '',
        computeMethod: 'NPS',
        displayModality: { isInteger: true, donutKeyModality: 0 },
        kpiGroupConfig: [],
        kpiModalityConfig: [],
        saveTreeItems: [],
        saveModalities: [],
        saveModalitiesGroup: [],
        rank: rankingParameter.rankToApply,
        dashboardColumn: rankingParameter.columnToApply,
    });

    const [selectedQuestion, setSelectedQuestion] = useState<SelectedQuestionProps>(editQuestion || {
        question_id: -999999,
        label: '',
        type: QUESTION_TYPE.NUMERIC,
        isMultiple: false,
        modality_count: 0,
        id_child_question: 0,
    });
    const [sendCreateIndicator, setSendCreateIndicator] = useState(false);
    const [selectedAnswersCount, setSelectedCountAnswer] = useState(0);
    const handleChangeCount = (valueToAdd: number) => {
        setSelectedCountAnswer(valueToAdd);
    };

    useEffect(() => {
        if (sendCreateIndicator) {
            if (isEdit) {
                recreateIndicatorFetcher(
                    createIndicator,
                    dashboardId,
                    surveyId,
                    t('addIndicatorModal.successAddIndicatorToaster'),
                    t('addIndicatorModal.errorAddIndicatorToaster'),
                );
            } else {
                createIndicatorFetcher(
                    createIndicator,
                    dashboardId,
                    surveyId,
                    t('addIndicatorModal.successAddIndicatorToaster'),
                    t('addIndicatorModal.errorAddIndicatorToaster'),
                );
            }
            mutate(dashboardUrl);
            mutate(`${dashboardUrl}/indicator`);
        }
    }, [sendCreateIndicator]);

    useEffect(() => {
        if (currentContent === 'addChatbotIndicator') {
            setOpenModal(false);
            setCreateIndicator((prev) => ({ ...prev, kpiType: 'OPEN' }));
            setSendCreateIndicator(true);
        }
    }, [currentContent]);

    useEffect(() => {
        if (isEdit && editIndicator) {
            setCreateIndicator(editIndicator);
        }
    }, [editIndicator]);

    useEffect(() => {
        if (isEdit && editQuestion) {
            setSelectedQuestion(editQuestion);
        }
    }, [editQuestion]);

    return (
        <Box>
            <CreateIndicatorContext.Provider value={{ sendCreateIndicator, setSendCreateIndicator }}>
                <Tabs
                    aria-label="create-indicator-tabs"
                    sx={{ visibility: 'hidden', width: '0px', height: '0px', minHeight: '0', display: 'block' }}
                    value={currentContent}
                >
                    <Tab
                        aria-controls='create-indicator'
                        label="selectIndicatorType"
                        value="selectIndicatorType"
                        wrapped
                    />
                    <Tab
                        aria-controls='select-question'
                        label="selectIndicatorQuestion"
                        value="selectIndicatorQuestion"
                        wrapped
                    />
                    <Tab
                        aria-controls='refine-indicator-answer'
                        label="refineIndicatorAnswer"
                        value="refineIndicatorAnswer"
                        wrapped
                    />
                    <Tab
                        aria-controls='select-display-type'
                        label="selectDisplayType"
                        value="selectDisplayType"
                        wrapped
                    />
                    <Tab
                        aria-controls='add-key-figure'
                        label="addKeyFigureIndicator"
                        value="addKeyFigureIndicator"
                        wrapped
                    />
                    <Tab
                        aria-controls='add-donut'
                        label="addDonutIndicator"
                        value="addDonutIndicator"
                    />
                    <Tab
                        aria-controls='add-horizontal-bar'
                        label="addHorizontalBarIndicator"
                        value="addHorizontalBarIndicator"
                    />
                    <Tab
                        aria-controls='add-positive-verbatim'
                        label="addPositiveVerbatimIndicator"
                        value="addPositiveVerbatimIndicator"
                        wrapped
                    />
                    <Tab
                        aria-controls='add-negative-verbatim'
                        label="addNegativeVerbatimIndicator"
                        value="addNegativeVerbatimIndicator"
                        wrapped
                    />
                    <Tab
                        aria-controls='send-indicator'
                        label="sendIndicator"
                        value="sendIndicator"
                        wrapped
                    />
                    <Tab
                        aria-controls='add-line-chart'
                        label="addLineChartIndicator"
                        value="addLineChartIndicator"
                        wrapped
                    />
                    <Tab
                        aria-controls='add-global-verbatim'
                        label="addGlobalVerbatimIndicator"
                        value="addGlobalVerbatimIndicator"
                        wrapped
                    />
                    <Tab
                        aria-controls='add-chatbot'
                        label="addChatbotIndicator"
                        value="addChatbotIndicator"
                        wrapped={false}
                    />
                </Tabs>
                <TabPanel
                    index='selectIndicatorType'
                    title={t('addIndicatorModal.selectIndicatorType.title')}
                    value={currentContent}
                >
                    <SelectIndicatorType
                        setCreateIndicator={setCreateIndicator}
                        setCurrentContent={setCurrentContent}
                    />
                </TabPanel>
                <TabPanel
                    index='selectIndicatorQuestion'
                    title={t('addIndicatorModal.selectIndicatorQuestion.title')}
                    value={currentContent}
                >
                    <SelectIndicatorQuestion
                        selectedQuestion={selectedQuestion}
                        setCreateIndicator={setCreateIndicator}
                        setCurrentContent={setCurrentContent}
                        setSelectedQuestion={setSelectedQuestion}
                    />
                </TabPanel>
                <TabPanel
                    index='refineIndicatorAnswer'
                    title={t('addIndicatorModal.refineIndicatorAnswer.title')}
                    value={currentContent}
                >
                    <RefineIndicatorAnswer
                        createIndicator={createIndicator}
                        selectedQuestion={selectedQuestion}
                        setCreateIndicator={setCreateIndicator}
                        setCurrentContent={setCurrentContent}
                    />
                </TabPanel>
                <TabPanel
                    index='selectDisplayType'
                    title={t('addIndicatorModal.selectDisplayType.title')}
                    value={currentContent}
                >
                    <SelectIndicatorDisplayType
                        selectedQuestion={selectedQuestion}
                        setCreateIndicator={setCreateIndicator}
                        setCurrentContent={setCurrentContent}
                    />
                </TabPanel>
                <TabPanel
                    index='addKeyFigureIndicator'
                    title={t('addIndicatorModal.addKeyFigureIndicator.title')}
                    value={currentContent}
                >
                    {createIndicator.questionId !== -999999 && selectedQuestion.question_id !== -999999 && (
                        <AddNumericIndicator
                            createIndicator={createIndicator}
                            displayType={CHART_INDICATOR_NAME.KEY_FIGURE}
                            isEdit={isEdit}
                            selectedQuestion={selectedQuestion}
                            setCreateIndicator={setCreateIndicator}
                            setCurrentContent={setCurrentContent}
                            setOpenModal={setOpenModal}
                        />
                    )}
                </TabPanel>
                <TabPanel
                    index='addDonutIndicator'
                    title={t('addIndicatorModal.addDonutIndicator.title')}
                    value={currentContent}
                >
                    {createIndicator.questionId !== -999999 && selectedQuestion.question_id !== -999999 && (
                        <AddNumericIndicator
                            createIndicator={createIndicator}
                            displayType={CHART_INDICATOR_NAME.DONUT}
                            isEdit={isEdit}
                            selectedQuestion={selectedQuestion}
                            setCreateIndicator={setCreateIndicator}
                            setCurrentContent={setCurrentContent}
                            setOpenModal={setOpenModal}
                        />
                    )}
                </TabPanel>
                <TabPanel
                    index='addHorizontalBarIndicator'
                    title={t('addIndicatorModal.addHorizontalBarIndicator.title')}
                    value={currentContent}
                >
                    {createIndicator.questionId !== -999999 && selectedQuestion.question_id !== -999999 && (
                        <AddNumericIndicator
                            createIndicator={createIndicator}
                            displayType={CHART_INDICATOR_NAME.HORIZONTAL_BAR}
                            isEdit={isEdit}
                            selectedQuestion={selectedQuestion}
                            setCreateIndicator={setCreateIndicator}
                            setCurrentContent={setCurrentContent}
                            setOpenModal={setOpenModal}
                        />
                    )}
                </TabPanel>
                <TabPanel
                    index='addPositiveVerbatimIndicator'
                    title={t('addIndicatorModal.addVerbatimIndicator.positiveTitle')}
                    value={currentContent}
                >
                    <AddVerbatimIndicator
                        selectedQuestion={selectedQuestion}
                        setCreateIndicator={setCreateIndicator}
                        setCurrentContent={setCurrentContent}
                        setOpenModal={setOpenModal}
                        isPositiveQuestion
                    />
                </TabPanel>
                <TabPanel
                    index='addNegativeVerbatimIndicator'
                    title={t('addIndicatorModal.addVerbatimIndicator.negativeTitle')}
                    value={currentContent}
                >
                    <AddVerbatimIndicator
                        isPositiveQuestion={false}
                        selectedQuestion={selectedQuestion}
                        setCreateIndicator={setCreateIndicator}
                        setCurrentContent={setCurrentContent}
                        setOpenModal={setOpenModal}
                    />
                </TabPanel>
                <TabPanel
                    index='addLineChartIndicator'
                    title={t('addIndicatorModal.addLineChartIndicator.title')}
                    value={currentContent}
                >
                    {createIndicator.questionId !== -999999 && selectedQuestion.question_id !== -999999 && (
                        <AddNumericIndicator
                            createIndicator={createIndicator}
                            displayType={CHART_INDICATOR_NAME.LINE_CHART}
                            isEdit={isEdit}
                            selectedQuestion={selectedQuestion}
                            setCreateIndicator={setCreateIndicator}
                            setCurrentContent={setCurrentContent}
                            setOpenModal={setOpenModal}
                        />
                    )}
                </TabPanel>
                <TabPanel
                    index='addGlobalVerbatimIndicator'
                    title={t('addIndicatorModal.addGlobalVerbatimIndicator.title', { selectedQuestionsCount: selectedAnswersCount, maxSelectedQuestions: 3 })}
                    value={currentContent}
                >
                    {createIndicator.questionId !== -999999 && selectedQuestion.question_id !== -999999 && (
                        <AddGlobalVerbatimIndicator
                            createIndicator={createIndicator}
                            handleChangeCount={handleChangeCount}
                            isEdit={isEdit}
                            maxSelectedQuestion={3}
                            selectedQuestion={selectedQuestion}
                            setCreateIndicator={setCreateIndicator}
                            setCurrentContent={setCurrentContent}
                            setOpenModal={setOpenModal}
                        />
                    )}
                </TabPanel>
            </CreateIndicatorContext.Provider>
        </Box>
    );
};

export default CreateIndicator;
