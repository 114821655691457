import { Chart as ChartJS, ArcElement, Tooltip, Legend, LegendItem } from 'chart.js';
import React, { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { colors, fontFamilyPrimary } from '../../../theme';
import { CALCULATION_MODE } from '../../Modals/DashboardModal/constants';

interface DonutCardProps {
    donutIndication: {
        keyFigure: string | {key:string, value: number},
        labels: string[]
        hasIndicatorKpiGroup: boolean,
        datasets: Array<{
            label: string
            data: (string | number)[]
            backgroundColor?: string[]
            hoverOffset?: number
        }>,
        round: number
    }
}

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutCard: FC<DonutCardProps> = ({ donutIndication }) => {
    const computeMethod = Object.keys(donutIndication.keyFigure)[0];
    const isNPSPositive = computeMethod === CALCULATION_MODE.NPS && donutIndication.keyFigure && Object.values(donutIndication.keyFigure)[0] > 0;
    const isAverage = computeMethod === CALCULATION_MODE.AVERAGE;
    const isNPS = computeMethod === CALCULATION_MODE.NPS;
    const plus = isNPSPositive ? '+' : '';
    const percent = !isNPSPositive && !isAverage && !isNPS ? '%' : '';
    const textInsideDonut = Object.values(donutIndication.keyFigure).length !== 0 ? `${plus}${Number(Object.values(donutIndication.keyFigure)).toFixed(donutIndication.round)}${percent}` : '';
    const plugins = [{
        id: `donut-${donutIndication.labels}`,
        beforeDraw(chart: any) {
            const key = Object.keys(donutIndication.keyFigure)[0];
            const textColor = chart.legend.legendItems?.find(({ text }: LegendItem) => text === key)?.fillStyle || colors.grey300;
            const { height } = chart;
            const { ctx } = chart;
            const { width } = chart.chartArea;
            const { legend } = chart;
            ctx.restore();
            const fontSize = (height / 80).toFixed(2);
            ctx.font = `700 ${fontSize}em  ${fontFamilyPrimary}`;
            ctx.textBaseline = 'top';
            ctx.textAlign = 'left';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = textColor;
            const text = textInsideDonut;
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            const textY = height / 2;
            legend.legendHitBoxes = undefined;
            ctx.fillText(text, textX, textY);
            ctx.save();
        },
    }];

    return (
        <Doughnut
            data={donutIndication}
            height={100}
            options={
                {
                    plugins: {
                        legend: {
                            position: 'right',
                            fullSize: true,
                            labels: {
                                boxWidth: 32,
                                boxHeight: 12,
                                padding: 20,
                                color: colors.grey200,
                                font: {
                                    family: fontFamilyPrimary,
                                    size: 14,
                                },
                            },
                            maxHeight: 1200,
                            maxWidth: 200,
                        },
                        datalabels: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: (dataset: any) => (`${dataset.label}: ${dataset.raw.toFixed(donutIndication.round)}%`),
                            },
                        },
                    },
                    layout: {
                        padding: 0,

                    },
                    cutout: '80%',
                    responsive: true,
                    aspectRatio: 2,
                    onHover: (e) => {
                        const doughnutElement = e.native?.target as HTMLInputElement;
                        doughnutElement.style.cursor = 'pointer';
                    },
                }
            }
            plugins={plugins}
            width={100}
        />
    );
};

export default DonutCard;
