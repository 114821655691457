import { IndicatorsOrder } from '../../components/Dashboard/dashboardInterfaces';
import { displayToast } from '../../components/Toaster/displayToast';

const updateIndicatorFetcher = (
    indicatorUrl: string,
    newIndicatorsOrder: IndicatorsOrder[],
    successMessage: string,
    errorMessage: string,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(newIndicatorsOrder),
    };

    fetch(indicatorUrl, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');
            } else {
                displayToast(successMessage, 'success');
            }
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default updateIndicatorFetcher;
