import {
    closestCorners,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    DragOverEvent,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { startOfMonth } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import createDashboardUpdateFetcher from '../../api/dashboard/updateDashoard';
import deleteFetcher from '../../api/deleteFetcher';
import updateIndicatorOrderFetcher from '../../api/indicator/updateIndicatorOrderFetcher';
import useFetcher from '../../utils/hooks/useFetcher';
import { CHART_INDICATOR_NAME } from '../Modals/DashboardModal/constants';
import { ChatingProps } from '../Modals/DashboardModal/dashboardModalInterface';
import { FilterResults } from '../Modals/FilterModal/filterModalInterface';
import { SurveyTemporalFilterType } from '../Surveys/surveyInterface';
import ColumnDroppable from './columnDroppable';
import DashboardHeader from './DashboardHeader/dashboardHeader';
import {
    Indicator,
    IndicatorData,
    IndicatorsOrder,
    DonutProps,
    VerbatimProps,
    DashboardFromApi,
    UpdateDashboardProps,
    FilterFromApi,
    ClauseFilterProps,
    ClauseFilterLabelProps,
    DashboardParams,
    DonutFromApiProps,
    KeyFigureFromApiProps,
    ScopeProps,
    ScopeLabelProps,
    TemporalScopeFromApi,
} from './dashboardInterfaces';
import FilterBar from './FilterBar/filterBar';
import Gradient from './GradientColor/Gradient';
import ChatbotIndicator from './IndicatorCard/chatbotIndicator';
import DonutIndicator from './IndicatorCard/donutIndicator';
import GlobalVerbatimIndicator from './IndicatorCard/globalVerbatimIndicator';
import HorizontalBarIndicator from './IndicatorCard/horizontalBarIndicator';
import KeyFigureIndicator from './IndicatorCard/keyFigureIndicator';
import LineChartIndicator from './IndicatorCard/lineChartIndicator';
import VerbatimIndicator from './IndicatorCard/verbatimIndicator';
import { ContainerDashboardCharts, DashboardStyled, SelectFiltersBar } from './styled';
import TemporalScope from './TemporalScope/temporalScope';

function isKeyFigureProps(object: IndicatorData): object is KeyFigureFromApiProps {
    return 'data' in object;
}

function isDonutProps(object: IndicatorData): object is DonutFromApiProps {
    return 'donut_result' in object;
}

function isVerbatimProps(object: IndicatorData): object is VerbatimProps {
    return 'positive' in object;
}

const Dashboard: FC = () => {
    const { t } = useTranslation('dashboard');
    const { mutate } = useSWRConfig();
    const { surveyId = 'unknown', dashboardId } = useParams<DashboardParams>();
    const [updatedDasboard, setUpdatedDasboard] = useState<UpdateDashboardProps>();
    const dashboardUrl = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}`;
    const fetcher = useFetcher();
    const { data: fetchedDashboard, error } = useSWR(dashboardUrl, fetcher);
    const { data: fetchedIndicators } = useSWR(`${dashboardUrl}/indicator`, fetcher);
    const { data: fetchedSurvey } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${surveyId}`, fetcher);
    const navigate = useNavigate();

    const [filters, setFilters] = useState<FilterFromApi[]>([]);
    const [temporalScopes, setTemporalScope] = useState<TemporalScopeFromApi[]>([]);
    const [selectedClauseFilter, setSelectedClauseFilter] = useState<Array<ClauseFilterProps>>([]);
    const [selectedClauseFilterLabel, setSelectedClauseFilterLabel] = useState<Array<ClauseFilterLabelProps>>([]);
    const [selectedScope, setSelectedScope] = useState<Array<ScopeProps>>([]);
    const [selectedScopeLabel, setSelectedScopeLabel] = useState<Array<ScopeLabelProps>>([]);
    const [resetFilters, setResetFilters] = useState<number>(0);
    const [dateRange, setDateRange] = useState<any>({
        selection: {
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
            key: 'selection',
        },
    });
    const constructDashboardFilterConfigFromApiToUpdate = (dashboardFilterConfig: DashboardFromApi['dashboardFilterConfig']): UpdateDashboardProps['dashboardFilterConfig'] => (dashboardFilterConfig.map((dashboardFilter: FilterFromApi) => ({ filterId: Object.keys(dashboardFilter.filter)[0], modalitiesIds: Object.keys(dashboardFilter.modalities) })));
    const handleSelectClauseFilter = ([filterId, modalityId]: [string, Array<string> | null]): void => {
        const indexOf = selectedClauseFilter.findIndex((alreadySelected: ClauseFilterProps) => alreadySelected.filterId === filterId);

        if (modalityId !== null && modalityId?.length !== 0) {
            if (indexOf === -1) {
                setSelectedClauseFilter([...selectedClauseFilter, { filterId, modalityId }]);
            } else {
                const newSelectedClauseFilter = [...selectedClauseFilter];
                newSelectedClauseFilter[indexOf] = { filterId, modalityId };
                setSelectedClauseFilter(newSelectedClauseFilter);
            }
        } else {
            const newSelectedClauseFilter = [...selectedClauseFilter];
            newSelectedClauseFilter.splice(indexOf, 1);
            if (indexOf !== -1) {
                setSelectedClauseFilter(newSelectedClauseFilter);
            }
        }
    };

    const handleSelectClauseFilterLabel = ([filterLabel, modalitiesLabel]: [string, string | null]): void => {
        const indexOf = selectedClauseFilterLabel.findIndex((alreadySelected: ClauseFilterLabelProps) => alreadySelected.filterLabel === filterLabel);

        if (modalitiesLabel !== null && modalitiesLabel?.length !== 0) {
            if (indexOf === -1) {
                setSelectedClauseFilterLabel([...selectedClauseFilterLabel, { filterLabel, modalitiesLabel }]);
            } else {
                const newSelectedClauseFilterLabel = [...selectedClauseFilterLabel];
                newSelectedClauseFilterLabel[indexOf] = { filterLabel, modalitiesLabel };
                setSelectedClauseFilterLabel(newSelectedClauseFilterLabel);
            }
        } else {
            const newSelectedClauseFilterLabel = [...selectedClauseFilterLabel];
            newSelectedClauseFilterLabel.splice(indexOf, 1);
            if (indexOf !== -1) {
                setSelectedClauseFilterLabel(newSelectedClauseFilterLabel);
            }
        }
    };

    const handleSelectedScope = ([scopeId, modalityId]: [string, Array<string> | null]): void => {
        const indexOf = selectedScope.findIndex((alreadySelected: ScopeProps) => alreadySelected.scopeId === scopeId);

        if (modalityId !== null && modalityId?.length !== 0) {
            if (indexOf === -1) {
                setSelectedScope([...selectedScope, { scopeId, modalityId }]);
            } else {
                const newSelectedScope = [...selectedScope];
                newSelectedScope[indexOf] = { scopeId, modalityId };
                setSelectedScope(newSelectedScope);
            }
        } else {
            const newSelectedScope = [...selectedScope];
            newSelectedScope.splice(indexOf, 1);
            if (indexOf !== -1) {
                setSelectedScope(newSelectedScope);
            }
        }
    };

    const handleChangeDateRangeScope = (newDateRange: any): void => {
        setDateRange(newDateRange);
    };

    const handleSelectedScopeLabel = ([scopeLabel, modalitiesLabel]: [string, string | null]): void => {
        const indexOf = selectedScopeLabel.findIndex((alreadySelectedLabel: ScopeLabelProps) => alreadySelectedLabel.scopeLabel === scopeLabel);

        if (modalitiesLabel !== null) {
            if (indexOf === -1) {
                setSelectedScopeLabel([...selectedScopeLabel, { scopeLabel, modalitiesLabel }]);
            } else {
                const newSelectedScopeLabel = [...selectedScopeLabel];
                newSelectedScopeLabel[indexOf] = { scopeLabel, modalitiesLabel };
                setSelectedScopeLabel(newSelectedScopeLabel);
            }
        } else {
            const newSelectedScopeLabel = [...selectedScopeLabel];
            newSelectedScopeLabel.splice(indexOf, 1);
            if (indexOf !== -1) {
                setSelectedScopeLabel(newSelectedScopeLabel);
            }
        }
    };

    const handleResetClauseFilter = (hasResetFilters: number) => {
        setResetFilters(resetFilters + hasResetFilters);
    };

    useEffect(() => {
        if (fetchedDashboard?.dashboardFilterConfig.length > 0) {
            const selectedClauseLockedFilters = fetchedDashboard?.dashboardFilterConfig.filter((filter: FilterFromApi) => filter.lockedModalities)?.map((filter: any) => (
                { filterId: Object.keys(filter.filter)[0], modalityId: filter.lockedModalities.toString().split(',') }
            ));
            const selectedClauseLockedFiltersLabel = fetchedDashboard?.dashboardFilterConfig.filter((filter: FilterFromApi) => filter.lockedModalities)?.map((filter: any) => (
                { filterLabel: Object.values(filter.filter)[0], modalitiesLabel: Object.entries(filter.modalities).filter((modality) => String(filter.lockedModalities).includes(modality[0])).map((modality) => modality[1]).join(', ') }
            ));
            setSelectedClauseFilter(selectedClauseLockedFilters ?? []);
            setSelectedClauseFilterLabel(selectedClauseLockedFiltersLabel ?? []);
        } else {
            setSelectedClauseFilter([]);
            setSelectedClauseFilterLabel([]);
        }
    }, [fetchedDashboard, resetFilters]);

    useEffect(() => {
        if (error) {
            navigate('/');
        }
    }, [error]);

    const constructUpdateDashboard = (dashboard: DashboardFromApi): UpdateDashboardProps => (
        {
            id: dashboard.id,
            name: dashboard.name,
            surveyId: dashboard.surveyId,
            rankOfIndicator: dashboard.rankOfIndicator,
            slug: dashboard.slug,
            customSurveyTitle: dashboard.customSurveyTitle,
            dashboardFilterConfig: constructDashboardFilterConfigFromApiToUpdate(dashboard.dashboardFilterConfig),
        }
    );

    useEffect(() => {
        if (fetchedDashboard) {
            setUpdatedDasboard(constructUpdateDashboard(fetchedDashboard));
            setFilters(fetchedDashboard.dashboardFilterConfig);
            setTemporalScope(fetchedDashboard.temporalScopesConfig ? fetchedDashboard.temporalScopesConfig : []);
        }
    }, [fetchedDashboard]);

    const addDashboardFilter = (newFilters: FilterResults): void => {
        const newUpdatedDasboard: UpdateDashboardProps = { ...updatedDasboard, dashboardFilterConfig: newFilters };
        createDashboardUpdateFetcher(
            surveyId,
            newUpdatedDasboard,
            t('addFilter.modal.success'),
            t('addFilter.modal.error'),
        );
        mutate(dashboardUrl);
    };

    const removeDashboardFilter = (filterId: string): void => {
        const urlToDeleteFilter = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/filterConfig/${filterId}`;
        deleteFetcher(t('removeFilter.success'), t('removeFilter.error'), urlToDeleteFilter);
        mutate(dashboardUrl);
        handleSelectClauseFilter([filterId, []]);
    };

    const selectIndicatorCard = (additionalVars: Indicator['additionalVars'], displayType: Indicator['displayType'], data: Indicator['data'], question: Indicator['question'], getChatbotResponse: (isSummary: boolean, question: string) => void, conversation: Array<ChatingProps>, summary: string, isSummaryDisplayed: boolean, waitingForChatAnswer: boolean, handleIsRenderedChat: (chatIdRendered: number) => void) => {
        if (displayType === CHART_INDICATOR_NAME.CHATBOT) {
            return (
                <ChatbotIndicator
                    conversation={conversation}
                    getChatbotResponse={getChatbotResponse}
                    handleIsRenderedChat={handleIsRenderedChat}
                    isSummaryDisplayed={isSummaryDisplayed}
                    question={question}
                    selectedClauseFilterLabel={selectedClauseFilterLabel}
                    summary={summary}
                    waitingForChatAnswer={waitingForChatAnswer}
                />
            );
        }

        if (data === undefined) {
            return null;
        }

        if (displayType === CHART_INDICATOR_NAME.KEY_FIGURE && isKeyFigureProps(data)) {
            return (
                <KeyFigureIndicator
                    computeMethod={Object.keys(data.data)[0]}
                    keyFigureNumber={Object.values(data.data)}
                    previousNumber={data?.previousNumber}
                    round={data?.round}
                    year={data?.year}
                />
            );
        }

        if (displayType === CHART_INDICATOR_NAME.DONUT && isDonutProps(data)) {
            let backgroundDonut = ['#22C55E', '#ff0000'];

            if (Object.values(data.donut_result).length > 2) {
                const firstGradient = new Gradient()
                    .setColorGradient('22C55E', 'FFCC00')
                    .setMidpoint(Object.values(data.donut_result).length / 2)
                    .getColors();
                const secondGradient = new Gradient()
                    .setColorGradient('F97316', 'ff0000')
                    .setMidpoint(Object.values(data.donut_result).length / 2)
                    .getColors();
                backgroundDonut = [...firstGradient, ...secondGradient];
            }
            const donutIndication: DonutProps = {
                keyFigure: data.donut_key_result ?? '',
                hasIndicatorKpiGroup: data.has_indicator_kpi_group,
                labels: Object.keys(data.donut_result),
                datasets: [{ label: 'label', data: Object.values(data.donut_result), backgroundColor: backgroundDonut }],
                round: data.round,
            };

            return (
                <DonutIndicator
                    donutIndication={donutIndication}
                />
            );
        }
        if (displayType === CHART_INDICATOR_NAME.VERBATIM_PN && isVerbatimProps(data)) {
            return (
                <VerbatimIndicator negative={data.negative} positive={data.positive} />
            );
        }
        if (displayType === CHART_INDICATOR_NAME.VERBATIM) {
            return (
                <GlobalVerbatimIndicator question={question} selectedClauseFilterLabel={selectedClauseFilterLabel} verbatims={data} />
            );
        }
        if (displayType === CHART_INDICATOR_NAME.HORIZONTAL_BAR) {
            return (
                <HorizontalBarIndicator
                    listOfValues={data}
                />
            );
        }
        if (displayType === CHART_INDICATOR_NAME.LINE_CHART) {
            return (
                <LineChartIndicator
                    listOfValues={data}
                />
            );
        }

        return undefined;
    };

    const [indicatorsByColumns, setIndicatorByColumns] = useState<{[key: number]: Array<Indicator>}>({});
    const [indicatorsOrder, setIndicatorsOrder] = useState<Array<IndicatorsOrder>>([]);
    const [isDragging, setIsDragging] = useState<string | null>(null);

    useEffect(() => {
        if (fetchedIndicators) {
            const constructIndicatorsByColumns : {[key: number]: Array<Indicator>} = {
                0: fetchedIndicators.filter((indicator: Indicator) => indicator.dashboardColumn === 0),
                1: fetchedIndicators.filter((indicator: Indicator) => indicator.dashboardColumn === 1),
                2: fetchedIndicators.filter((indicator: Indicator) => indicator.dashboardColumn === 2),
            };

            setIndicatorByColumns({ ...constructIndicatorsByColumns });
            setIndicatorsOrder(fetchedIndicators.map((indicator: Indicator) => ({ id: indicator.id, dashboard_column: indicator.dashboardColumn, rank: indicator.rank })));
        }
    }, [fetchedIndicators, dashboardUrl]);

    const setIndicatorsNewOrder = () => {
        const prevIndicatorsOrder = [...indicatorsOrder];
        const newIndicatorOrders = Object.keys(indicatorsByColumns).flatMap((columnId: string) => (
            indicatorsByColumns[parseInt(columnId, 10)].map((indicator: Indicator, index) => ({ id: indicator.id, dashboard_column: parseInt(columnId, 10), rank: index }))
        ));
        if ((JSON.stringify(prevIndicatorsOrder) !== JSON.stringify(newIndicatorOrders))) {
            setIndicatorsOrder(newIndicatorOrders);
            updateIndicatorOrderFetcher(
                `${dashboardUrl}/indicator/update_order`,
                newIndicatorOrders,
                t('rankingIndicatorModal.success'),
                t('rankingIndicatorModal.error'),
            );
        }
    };

    const findColumn = (unique: string | null) => {
        if (!unique) {
            return null;
        }
        if (Object.keys(indicatorsByColumns).some((c: string) => c === unique)) {
            return unique ? { id: unique, cards: indicatorsByColumns[parseInt(unique, 10)] } : null;
        }
        const id = String(unique);
        const itemWithColumnId = Object.keys(indicatorsByColumns).flatMap((c: string) => {
            const columnId = c;

            return indicatorsByColumns[parseInt(c, 10)].map((i: Indicator) => ({ itemId: i.id, columnId }));
        });
        const columnId = itemWithColumnId.find((i) => String(i.itemId) === id)?.columnId;

        return columnId ? { id: columnId, cards: indicatorsByColumns[parseInt(columnId, 10)] } : null;
    };

    const arraysAreEqual = (arr1: Array<Indicator>, arr2: Array<Indicator>): boolean => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        return arr1.every((item, index) => item.id === arr2[index].id);
    };

    useEffect(() => {
        if (isDragging === '-1') {
            setIndicatorsNewOrder();
            mutate(
                `${dashboardUrl}/indicator`,
                undefined,
                { revalidate: false },
            );
            setIsDragging(null);
        }
    }, [indicatorsByColumns, isDragging]);

    const handleDragOver = (event: DragOverEvent) => {
        const { active, over, delta } = event;
        const activeId = String(active.id);
        // Only update isDragging if it's different
        if (isDragging !== activeId) {
            setIsDragging(activeId);
        }
        const overId = over ? String(over.id) : null;
        const activeColumn = findColumn(activeId);
        const overColumn = findColumn(overId);
        if (!activeColumn || !overColumn || activeColumn.id === overColumn.id) {
            return null;
        }
        setIndicatorByColumns((prevState) => {
            const activeItems = activeColumn.cards;
            const overItems = overColumn.cards;
            const activeIndex = activeItems.findIndex((i: Indicator) => String(i.id) === activeId);
            const overIndex = overItems.findIndex((i: Indicator) => String(i.id) === overId);
            const newIndex = () => {
                const putOnBelowLastItem = overIndex === overItems.length - 1 && delta.y > 0;
                const modifier = putOnBelowLastItem ? 1 : 0;

                return overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            };
            const newIndicatorsByColumns: {[key: number]: Array<Indicator>} = { ...prevState };
            let hasChanges = false;
            for (let col = 0; col < 3; col++) {
                if (String(col) === activeColumn.id) {
                    const newColumn = activeItems.filter((i) => String(i.id) !== activeId);
                    if (!arraysAreEqual(newColumn, prevState[col])) {
                        newIndicatorsByColumns[col] = newColumn;
                        hasChanges = true;
                    }
                } else if (String(col) === overColumn.id) {
                    const newColumn = [
                        ...overItems.slice(0, newIndex()),
                        activeItems[activeIndex],
                        ...overItems.slice(newIndex(), overItems.length),
                    ];
                    if (!arraysAreEqual(newColumn, prevState[col])) {
                        newIndicatorsByColumns[col] = newColumn;
                        hasChanges = true;
                    }
                } else {
                    newIndicatorsByColumns[col] = prevState[col];
                }
            }

            return hasChanges ? newIndicatorsByColumns : prevState;
        });

        return undefined;
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        setIsDragging('-1');
        const activeId = String(active.id);
        const overId = over ? String(over.id) : null;
        const activeColumn = findColumn(activeId);
        const overColumn = findColumn(overId);
        if (!activeColumn || !overColumn || activeColumn.id !== overColumn.id) {
            return null;
        }
        const activeIndex = activeColumn.cards.findIndex((i) => String(i.id) === activeId);
        const overIndex = overColumn.cards.findIndex((i) => String(i.id) === overId);
        if (activeIndex !== overIndex) {
            setIndicatorByColumns((prevState) => {
                const newIndicatorsByColumns = { ...prevState };
                const columnIndex = parseInt(activeColumn.id, 10);
                newIndicatorsByColumns[columnIndex] = arrayMove(
                    overColumn.cards,
                    activeIndex,
                    overIndex,
                );

                return newIndicatorsByColumns;
            });
        }

        return undefined;
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    return (
        <DashboardStyled>
            <DashboardHeader name={fetchedSurvey?.customSurveyTitle || fetchedSurvey?.name} />
            <SelectFiltersBar>
                <TemporalScope
                    dashboardId={Number(dashboardId)}
                    dateRange={dateRange}
                    handleChangeDateRangeScope={handleChangeDateRangeScope}
                    handleSelectedScope={handleSelectedScope}
                    handleSelectedScopeLabel={handleSelectedScopeLabel}
                    maxDate={new Date()}
                    minDate={new Date(fetchedSurvey?.minDate)}
                    selectedScopeLabel={selectedScopeLabel}
                    temporalFilterType={fetchedSurvey?.temporalFilterType}
                    temporalScopes={temporalScopes}
                />
                <FilterBar
                    alreadySelectedFilter={updatedDasboard?.dashboardFilterConfig}
                    filters={filters}
                    handleResetClauseFilter={handleResetClauseFilter}
                    handleSelectClauseFilter={handleSelectClauseFilter}
                    handleSelectClauseFilterLabel={handleSelectClauseFilterLabel}
                    removeDashboardFilter={removeDashboardFilter}
                    resetFilters={resetFilters}
                    selectedClauseFilterLabel={selectedClauseFilterLabel}
                    setUpdatedDasboard={setUpdatedDasboard}
                    updateDashboard={addDashboardFilter}
                />
            </SelectFiltersBar>
            <ContainerDashboardCharts>
                <DndContext
                    collisionDetection={closestCorners}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    sensors={sensors}
                >
                    {Object.keys(indicatorsByColumns).map((columnNumber: string) => (
                        <ColumnDroppable
                            key={columnNumber}
                            columnNumber={columnNumber}
                            indicatorsByColumns={indicatorsByColumns}
                            isDragging={isDragging}
                            selectIndicatorCard={selectIndicatorCard}
                            selectedClauseFilter={selectedClauseFilter}
                            selectedDateRange={fetchedSurvey?.temporalFilterType === SurveyTemporalFilterType.DATE ? dateRange.selection : undefined}
                            selectedScope={selectedScope}
                        />
                    ))}
                </DndContext>
            </ContainerDashboardCharts>
        </DashboardStyled>
    );
};

export default Dashboard;
